import React from 'react';

import HomeComponent from './_component/homeComponent.jsx';

class Home extends React.Component {
    render() {
        return (
            <>
                <HomeComponent />
            </>
        )
    }
}

export default Home;
