import React from 'react';
import { connect } from "react-redux";
import IframeComm from "react-iframe-comm";
import '../../../assets/css/style.scss';
//import $ from 'jquery';

import { logoutUser } from "../../../actions/auth";
import DialogBox from '../../../components/dialog/dialogBoxComponent.jsx';
import Nav from '../../../components/nav/navComponent.jsx';

class HomeComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      message: 1,
      open: false,
      modalBody: {
        type: null,
        title: null,
        body: null,
        uuid: null,
      }
    }
    this.thumbnail = {
      demo: [],
      webinar: [],
      media: []
    }

    // setThumbnail
    this.setThumbnailAssets();

    this.iframeContent = null;
    //src: "https://playcanv.as/p/EHmUjXbK/",
    //src: "http://localhost:3000/experience/index.html"
    this.attributes = {
      id: "mPlayCanvas",
      src: "./experience/index.html",
      // src: "https://playcanv.as/e/p/EHmUjXbK/",
      width: "100%",
      height: "100%",
      frameBorder: 0, // show frame border just for fun...
      //   sandbox: "allow-scripts",
      allow: "autoplay",
    };

    this.dialog = React.createRef();

    this.postMessageData = "hello iframe from outside";
    this.onReceiveMessage = this.onReceiveMessage.bind(this);

    this.logOutHandler = this.logOutHandler.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.handleSetModalContentFromHotspotSelected = this.handleSetModalContentFromHotspotSelected.bind(this);

    this.handleOpenDialogBoxAndPassingContent = this.handleOpenDialogBoxAndPassingContent.bind(this);
  }

  componentDidMount() {

  }

  /**
   * @function handlePureChatBox()
   * @description Handler listener on chanrge to render Tidio live Chat.
   */
  handleTidioChatBox() {
    const body = document.querySelector('body');
    const script = document.createElement("script");
    script.async = true;
    script.id = 'liveChat'
    script.src = "//code.tidio.co/cncyulahg7fhjyuijy6xdc94a883ccxy.js";
    body.appendChild(script);
  }

  /**
   * @function setThumbnailAssets()
   * @description Populate thumbnail array state to be use in UI. 
   */
  setThumbnailAssets() {

    this.thumbnail.demo.push({ type: 'video-embed', video: 'https://player.vimeo.com/video/255931354?autoplay=1', image: require(`../../../assets/img/th_demo_${0}.png`) });
    this.thumbnail.demo.push({ type: 'video-embed', video: 'https://player.vimeo.com/video/429808681?autoplay=1', image: require(`../../../assets/img/th_demo_${1}.png`) });
    this.thumbnail.demo.push({ type: 'video-embed', video: 'https://player.vimeo.com/video/422190445?autoplay=1', image: require(`../../../assets/img/th_demo_${2}.png`) });

    this.thumbnail.webinar.push({ type: 'video-embed', video: 'https://player.vimeo.com/video/429811394?autoplay=1', image: require(`../../../assets/img/th_webinar_${0}.png`) });
    this.thumbnail.webinar.push({ type: 'video-embed', video: 'https://player.vimeo.com/video/429808681?autoplay=1', image: require(`../../../assets/img/th_webinar_${1}.png`) });
    this.thumbnail.webinar.push({ type: 'video-embed', video: 'https://player.vimeo.com/video/255931354?autoplay=1', image: require(`../../../assets/img/th_webinar_${2}.png`) });

    // for (let i = 0; i < 3; i++) {
    //   this.thumbnail.demo.push({src:'https://vimeo.com/429808681/7c77d4a469' , image: require(`../../../assets/img/th_demo_${i}.png`)});
    //   this.thumbnail.webinar.push(require(`../../../assets/img/th_webinar_${i}.png`));
    // }
  }

  /**
   * @function setScore()
   * @param data
   * @description Emit cross-comumnication between playcanvas, iframe.
   */
  setScore = (data) => {
    console.log('test');
  };

  /**
   * @function onReady()
   * @description Cross-communication loaded and ready listener.
   */
  onReady = () => {
    console.log("onReady");
  };

  /**
   * @function toggleFullscreen()
   * @description Full screen toggle listener iframe-playcanvas.
   */
  toggleFullscreen() {
    // check if fullscreen mode is available
    var checkFullScreen = document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;

    if (checkFullScreen) {
      var doc = window.document;
      var iframe = document.querySelector('.playcanvasCon');

      var requestFullScreen = iframe.requestFullscreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen || iframe.msRequestFullscreen;
      var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(iframe);
      }
      else {
        cancelFullScreen.call(doc);
      }
    }
    else {
      alert('Your browser does not support fullscreen mode.');
    }
  }

  /**
   * @function onReceiveMessage()
   * @param {@} event 
   * @description Cross-communication parent receiver message from
   * iframe wrapper.
   */
  onReceiveMessage(event) {
    //console.log(event.data);
    if (event.data.type === "HotspotHit") {
      console.log("Hit Hotspot: " + event.data);
      this.handleSetModalContentFromHotspotSelected(event.data);
    }
  };

  /**
   * @function onSendMessage()
   * @description Post Message data listener cross-communication.
   */
  onSendMessage = () => {
    this.setState({ message: this.state.message + 1 });
    console.log("onSendMessage: " + this.state.message);
    this.postMessageData = this.state.message;
  };



  /**
   * @function logOutHandler()
   * @description Logout listener state by props connection.
   */
  logOutHandler() {
    const { dispatch } = this.props;
    dispatch(logoutUser());
  };

  /**
   * @function handleCloseModal()
   * @description Close modal listener and dispatch video for
   *  any memory lik.
   */
  handleCloseModal() {
    var videos = document.querySelectorAll('video, video');
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause();
      } else {
        var src = video.src;
        video.src = src;
      }
    });
  }

  /**
   * @function handleClickOpen()
   * @param {*} title 
   * @param {*} url 
   * @description Open Modal lisneter interacted by props between
   * child components (dialog-component), current event handles video
   * mp4 tag.
   */
  handleClickOpen(title, url) {
    //let temp = { video: 'https://mdbootstrap.com/img/video/Sail-Away.mp4' };
    console.log('url:', url);
    this.setState({
      open: true,
      modalBody: {
        type: 'video',
        title: title,
        body: url, //temp,
        uuid: 0
      }
    });
  };

  /**
   * @function handleOpenDialogBoxAndPassingContent()
   * @param {*} title 
   * @param {*} url 
   * @description Open Modal lisneter interacted by props between
   * child components (dialog-component), current event handles content 
   * by type tag.
   */
  handleOpenDialogBoxAndPassingContent(item) {
    this.setState({
      open: true,
      modalBody: {
        type: item.item.type,
        title: item.title,
        body: item.item,
        uuid: 0
      }
    });
  };

  /**
   * @function handleClose()
   * @param {*} value 
   * @description Close modal lisenter interacted by props between
   * child component (dialog-component);
   */
  handleClose(value) {
    this.setState({ open: value.open, modalBody: {} })
  };

  handleSetModalContentFromHotspotSelected(hostpot) {
    //console.log('POPULATE HOTSPOT:', hostpot);
    switch (hostpot.command) {
      case 'popup':
        let temp =
          `Inhance Digital is an award-winning interactive 
        agency focused on creating cutting edge and compelling 
        digital content.`;
        this.setState(
          {
            open: true,
            modalBody: {
              title: hostpot.data.displayName,
              body: temp
            },
          });
        break
      case 'content':
        //TODO: set conetent dynamicly...
        console.log('About to set content from section selected!');
        break
      case 'live-stream':
        this.setState(
          {
            open: true,
            modalBody: {
              type: 'conference_embeded',
              title: 'Video Conference',
              body: { video: 'https://fathomless-refuge-38916.herokuapp.com/'}
            },
          });
        break
      case 'popup-PDF':
        this.setState(
          {
            open: true,
            modalBody: {
              type: 'pdf',
              title: hostpot.data.displayName,
              body: require('../../../assets/media/pdf/prevention-H.pdf')
            },
          });
        break
      case 'popup-PDFS':
        let array = [
          {
            id: 0,
            description: 'prevention',
            img: require('../../../assets/img/th_demo_0.png'),
            pdf: require('../../../assets/media/pdf/prevention-H.pdf')
          },
          {
            id: 1,
            description: '2019-factsheet',
            img: require('../../../assets/img/th_demo_1.png'),
            pdf: require('../../../assets/media/pdf/2019-ncov-factsheet.pdf')
          },
          {
            id: 2,
            description: 'share-facts',
            img: require('../../../assets/img/th_demo_2.png'),
            pdf: require('../../../assets/media/pdf/share-facts-h.pdf')
          },
          {
            id: 3,
            description: 'prevention-H',
            img: require('../../../assets/img/th_webinar_0.png'),
            pdf: require('../../../assets/media/pdf/prevention-H.pdf')
          },
          {
            id: 4,
            description: 'preventio-H-1',
            img: require('../../../assets/img/th_webinar_1.png'),
            pdf: require('../../../assets/media/pdf/prevention-H.pdf')
          },
          {
            id: 5,
            description: 'preventio-H-2',
            img: require('../../../assets/img/th_webinar_2.png'),
            pdf: require('../../../assets/media/pdf/prevention-H.pdf')
          }
        ]
        this.setState(
          {
            open: true,
            modalBody: {
              type: 'pdf',
              title: hostpot.data.displayName,
              body: array
            },
          });
        break
      default:
        break
    }
  }


  render() {
    return (
      <>
        <Nav />
        <div className="playcanvasCon">
          <IframeComm
            attributes={this.attributes}
            postMessageData={this.postMessageData}
            handleReady={this.onReady}
            handleReceiveMessage={this.onReceiveMessage}
            targetOrigin="*"
          />
          <DialogBox content={this.state.modalBody} open={this.state.open} handleClose={this.handleClose}></DialogBox>

          <div className="icon fullscreen" onClick={() => this.toggleFullscreen()}></div>
        </div>
        <div className="borderRed"></div>
        <footer>
          <div className="contentCon">
            <div className="joinusBox container p-0">
              <div className="socialBox p-3">
                <span>Join us in this unique healthcare engagement, as we share our virtual experience platform solution!</span>
                <div className="icon float-right email"></div>
                <div className="icon float-right twitter"></div>
                <div className="icon float-right facebook"></div>
                <div className="icon float-right linkedin"></div>
              </div>
            </div>
            <div className="middleRowCon">
              <div className="middleRow container row p-0">


                {/*** START DYNAMIC CONTENT ******************************************/}
                <div className="leftBox col-lg-6">
                  <div className="thumbTitle row mt-3">
                    <div className="col">Virtual Product Demo Area</div>
                  </div>
                  <div className="thumbBox row mt-2">
                    {this.thumbnail.demo.map((element, i) => {
                      return (
                        <div key={i} className="thumbGroup col-lg-4 mt-1 mb-1 p-1">
                          <div className="thumb card">
                            <img
                              onClick={() => this.handleOpenDialogBoxAndPassingContent({
                                title: "Virtual Product Demo Area",
                                item: element
                              })}
                              className="card-img"
                              id={'th_demo_' + i}
                              src={element.image} alt="thumbnail" />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="thumbDiscript row"><div className="col">Inhance Digital is an award-winning interactive agency focused on creating cutting edge and compelling digital content, interactive applications and tools for the world's leading organizations. We bring the best talent and technologies from Hollywood visual effects and video games to simplify understanding of complex science and technology concepts.</div></div>
                </div>
                <div className="rightBox col-lg-6">
                  <div className="thumbTitle row mt-3">
                    <div className="col">Webinar Speaker Series</div>
                  </div>
                  <div className="thumbBox row mt-2">
                    {this.thumbnail.webinar.map((element, i) => {
                      return (
                        <div key={i} className="thumbGroup col-lg-4 mt-1 mb-1 p-1">
                          <div className="thumb card">
                            <img
                              onClick={() => this.handleOpenDialogBoxAndPassingContent({
                                title: 'Webinar Speaker Series',
                                item: element
                              })}
                              className="card-img"
                              id={'th_webinar_' + i}
                              src={element.image} alt="thumbnail" />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="thumbDiscript row"><div className="col">We believe the future of live and experiential marketing and comunications includes a virtual engagement model. This model soes not replace the need for trade shows, exhibits and speaker engagements it extends it.</div></div>
                </div>
                {/*** END DYNAMIC CONTENT ******************************************/}


              </div>
            </div>
            <div className="lowerRowCon">
              <div className="lowerRow container row p-0">
                <div className="leftBox col-lg-6">
                  <div className="textBox row pt-5 pb-5">
                    <span className="eie">ENGAGE.</span>
                    <span className="eie">INFORM.</span>
                    <span className="eie">EXTEND.</span>
                    <span className="discript">We've been showing clients for the past 22 years how to engage their core customer with engaging interactive digital content. We're taking that knowledge and expertise with interactive web technology and creating an easy to use platform that ofers an event experience that can be accessed anywhere, and anytime.</span>
                  </div>
                </div>
                <div className="rightBox col-lg-6">
                  <div className="textBox row pt-5 pb-5">
                    <div className="icon"></div>
                    <div className="eLITER">
                      <span className="up">eLITERATURE</span>
                      <span className="togo">TO GO</span>
                    </div>
                    <div className="titletext">
                      <span className="discript">Using our content management system, you'll be able to upload and host or point to your most recent and approved content. Add items to your folder and choose to download on the device of their choice.</span>
                    </div>
                    <div className="scrollBox">
                      <div className="innerBox">
                        <div className="icon pdf"></div>
                        <div className="textline"><span>Q2/2020 PRODUCT OVERVIEW</span></div>
                      </div>
                      <div className="innerBox">
                        <div className="icon img"></div>
                        <div className="textline"><span>Brand ABC Product Brochure Approved for distribution</span></div>
                      </div>
                      <div className="innerBox">
                        <div className="icon doc"></div>
                        <div className="textline"><span>Brand ABC Product Efficacy Report UPDATE</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footerCon container pl-0 pr-0 mt-3">
            <div className="upperRow row mt-3">
              <div className="footerButton col mb-3">Contact</div>
              <div className="footerButton col mb-3">Privacy Notice</div>
              <div className="footerButton col mb-3">Compliance Statement</div>
              <div className="footerButton col mb-3">Cookie Statement</div>
              <div className="footerButton col mb-3">Resources</div>
              <div className="footerButton col mb-3">Facebook</div>
              <div className="footerButton col mb-3">Twitter</div>
              <div className="footerButton col mb-3">Linkedin</div>
            </div>
            <div className="lowerRow row mt-3 mb-3">
              <div className="footerButton col">2020 Inhance Digital | All lights reserved</div>
            </div>
          </div>
        </footer>

      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    hostposts: state
  };
}

export default (connect(mapStateToProps)(HomeComponent));