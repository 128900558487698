export const FILES_REQUEST = "FILES_REQUEST";

const filesCounter = (download) => {
  return {
    type: FILES_REQUEST,
    download
  }
}

export const counterFiles = (download) => dispatch => {
  dispatch(filesCounter(download))
}