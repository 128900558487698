import {
    FILES_REQUEST
  } from "../actions/download";
  
  export default (
    state = {
      download: []
    },
    action
  ) => {
    switch (action.type) {
      case FILES_REQUEST:
        return {
          ...state,
          download: action.download
        }
      default:
        return state;
    }
  };