
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";


// vbui-dev
// const firebaseConfig = {
//   apiKey: "AIzaSyBpUO-rIJ5Nw4rlCx8chSKib9a4BKAXYI4",
//   authDomain: "virtual-booth.firebaseapp.com",
//   databaseURL: "https://virtual-booth.firebaseio.com",
//   projectId: "virtual-booth",
//   storageBucket: "virtual-booth.appspot.com",
//   messagingSenderId: "693544432421",
//   appId: "1:693544432421:web:0c51dd355a384b11f9e0cc",
//   measurementId: "G-M9GLFEM9Y5"
// };


// demovb
const firebaseConfig = {
  apiKey: "AIzaSyBpUO-rIJ5Nw4rlCx8chSKib9a4BKAXYI4",
  authDomain: "virtual-booth.firebaseapp.com",
  databaseURL: "https://virtual-booth.firebaseio.com",
  projectId: "virtual-booth",
  storageBucket: "virtual-booth.appspot.com",
  messagingSenderId: "693544432421",
  appId: "1:693544432421:web:d322ba2c292d273bf9e0cc",
  measurementId: "G-M8CM2MN1HN"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;