import React from 'react';

import Login from '../modules/login/index.jsx';

class LoginComponentView extends React.Component  {
  render() {
    return (
      <div>
        <Login />    
      </div>
    )
  }

}

export default LoginComponentView;
