import React from 'react';
import { connect } from "react-redux";


import '../nav/style.scss';

import logo from '../../assets/img/logo.png';
import searchIcon from '../../assets/img/icon_search.png';
import envelopIcon from '../../assets/img/envelop.jpg';
import avatarIcon from '../../assets/img/icon_user.png';
import covIcon from '../../assets/img/icon_covid19.png';
import learnIcon from '../../assets/img/btn_learnMore.png';

import {
    Navbar,
    Nav,
    Form
} from 'react-bootstrap';

import { logoutUser } from "../../actions/auth";

class NavBar extends React.Component {
    constructor(props) {
        super(props)

        this.logOutHandler = this.logOutHandler.bind(this);
    }
    logOutHandler() {
        const { dispatch } = this.props;
        dispatch(logoutUser()); 
    };

    render() {
        return (
            <>
                <Navbar expand="lg">
                    <Navbar.Brand>
                        <img
                            src={logo}
                            width="150"
                            height="50"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"></Nav>
                        <Form inline>
                            <img src={searchIcon} width="40" height="40" alt="" />
                            <img src={envelopIcon} width="40" height="40" alt="" />
                            <img src={avatarIcon} width="40" height="40" alt="" />
                            <img src={covIcon} width="100" height="35" alt="" />
                            <div className="-separator-border"></div>
                            <img className="-icons-cursor" src={learnIcon} width="100" height="35" alt="" onClick={this.logOutHandler} />
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
            </>
        )
    }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(NavBar);