import * as React from "react";
import { connect } from "react-redux";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import Login from '../views/loginComponentView.jsx';
import Home from '../views/homeComponentView.jsx';

//tester@inhance.com
//8o57Beverly!
const Pages = (props) => {
    const { isAuthenticated, isVerifying } = props;
    return (
        <Router>
            <Switch>
                <ProtectedRoute
                    exact
                    path="/"
                    component={Home}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <Route path="/login" component={Login} />
            </Switch>
        </Router>
    );
};
function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying
    };
}

export default connect(mapStateToProps)(Pages);