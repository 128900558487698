import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Fab from '@material-ui/core/Fab';
import CloseRounded from '@material-ui/icons/CloseRounded';

import '../../assets/css/style.scss';

import { counterFiles } from '../../actions/download';

//import Google from '../../utils/google';

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden'
    },
    gridList: {
        flexWrap: 'nowrap',
        width: '100%',
        height: 'auto',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)'
    },
    title: {
        color: 'white',
        margin: 'auto'
    },
    titleBar: {
        background: 'rgba(51, 53, 55, 1)'
    },
    list: {
        width: '100%',
    },
    formContainer: {
        // display: 'flex',
        flexWrap: 'wrap',
        top: '2vh',
        marging: 'auto'
    },
    textField: {
        width: '25ch'
    },
    margin: {
        'padding-top': '25px'
    },
    cancelBtn: {
        position: 'absolute',
        left: '10px'
    },
    warningMsg: {
        color: '#ff1602'
    },
    embededChat: {
        position: 'absolute',
        top:'10vh',
        left: '10vw',
        height: '80vh',
        width: '80vw'
    }
});

class DialogContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            content: [],
            addItem: false,
            checked: [],
            download: [],
            pdfSelected: null,
            classes: null,
            password: null,
            errorMessage: null
        }


        this.handleToggle = this.handleToggle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleDisplayPDFSelected = this.handleDisplayPDFSelected.bind(this);
        this.handleContentTypeToDisplay = this.handleContentTypeToDisplay.bind(this);
        this.handleGalleryThumnail = this.handleGalleryThumnail.bind(this);

        this.handleSetItemsToDownload = this.handleSetItemsToDownload.bind(this);
        this.handleDownloadFiles = this.handleDownloadFiles.bind(this);

        this.handleGoogleTagManagerEventListener = this.handleGoogleTagManagerEventListener.bind(this);

        this.handleJoinAsHostFormOrAsViewerOnChange = this.handleJoinAsHostFormOrAsViewerOnChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            content: nextProps.content,
            pdfSelected: Array.isArray(nextProps.content.body) ? nextProps.content.body[nextProps.content.index - 1] : nextProps.content.body,
        });

        // Submit google tag manager event to server
        if (nextProps.open) {
            this.handleGoogleTagManagerEventListener(nextProps)
        } else {
            return
        }
    }

    /**
     * @function handleGoogleTagManagerEventListener()
     * @param {*} elementId 
     * @description Handler listener on charge of fire an event that will
     * be sed to Google Tag Manager.
     */
    handleGoogleTagManagerEventListener(props) {
        const googleTagManager = {
            experienceId: '024',
            sessionId: '7ae52e46-72ec-46b0-8112-eff23c580715',
            itemType: props.content.type,
            uri: 'https://alkermes-ui-dev.firebaseapp.com/',
        }
        switch (props.content.type) {
            case 'embed':
            case 'video':
            case 'pdf':
                googleTagManager.order = props.content.times;
                googleTagManager.itemId = Array.isArray(props.content.body) ? props.content.body[props.content.index - 1].uuid : props.content.body;
                googleTagManager.itemIndex = props.content.index;
                googleTagManager.item = Array.isArray(props.content.body) ? props.content.body[props.content.index - 1] : props.content.body;
                //console.log('content:', googleTagManager);
                //new Google().fetch('https://media.inhance.io:7444/api/v1/store-result', { method: 'POST', body: googleTagManager})
                break
            case 'download':
                googleTagManager.item = props.content.body;
                //new Google().fetch('https://media.inhance.io:7444/api/v1/store-result', { method: 'POST', body: googleTagManager})
                break
            case 'conference':

                break
            default:
                break
        }
    }

    /**
     * @function handleToggle()
     * @param {*} value 
     * @description Handle toggle listener on charge of check-boxes
     * states.
     */
    handleToggle(value) {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = [...this.state.checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({ checked: newChecked });
    };

    /**
     * @function handleClose()
     * @description Close dialog box handler by dynamic props
     * from parent component.
     */
    handleClose() {
        this.setState({ password: null, errorMessage: null });
        this.props.handleClose({ open: false });
    };

    /**
     * @function handleOnChange()
     * @param {*} event 
     * @description onChange listener on charge of upating input data.
     */
    handleOnChange(event) {
        this.setState({ password: event.target.value === '8057Beverly' ? true : false });
    };

    /**
     * @function handleDownloadFiles()
     * @description Envent listener on charge of download pdf selected
     * on sharing-card.
     */
    handleDownloadFiles() {
        if (this.state.checked.length <= 0) return;
        let triggerContentToDownload = { content: { type: 'download', body: [] } };

        this.state.checked.forEach((item, i) => {
            let file = require(`../../assets/media/pdf/${item}.pdf`)
            let link = document.createElement('a');
            link.href = file;
            link.download = file.substr(file.lastIndexOf('/') + 1);
            link.click();

            // Trigger items downloaded
            this.state.content.body.forEach((element) => {
                element.description === item
                    ? triggerContentToDownload.content.body.push(element)
                    : console.log('\u{1F600} trigger sent!');
            })
            //this.handleClose();
        });
        this.handleGoogleTagManagerEventListener(triggerContentToDownload)
    }

    /**
     * @function handleDisplayPDFSelected()
     * @param {*} event 
     * @description Managing (pdf,remove/add) selected dynamicly
     * to be render.
     */
    handleDisplayPDFSelected(event) {
        //console.log('You selected id:', event.target.dataset)
        switch (event.target.dataset.set) {
            case 'selected':
                let self = this;
                this.state.content.body.forEach(item => {
                    if (item.id === Number(event.target.id)) {
                        self.setState({ pdfSelected: item })
                    }
                });
                break
            default:
                break
        }
    }

    /**
     * @function handleSetItemsToDownload()
     * @param {@} event 
     * @description Event listener on charge to handle add/remove item 
     * to be dowloand for later.
     */
    handleSetItemsToDownload(event) {
        const div = document.getElementById(event.target.id);
        div.innerHTML = '';
        if (div.classList.contains('active')) {
            div.classList.remove('active');
            div.innerHTML = '+';
            //this.state.download.splice(this.state.download.indexOf(event.target.name), 1);
            this.state.download.forEach((item, i) => {
                item.description === event.target.name
                    ? this.state.download.splice(i, 1)
                    : console.log('\u{1F600} Item no found!')
            })
            //console.log('deleted:', this.state.download)
        } else {
            div.classList.add('active');
            div.innerHTML = '-';

            this.state.content.body.forEach((item, i) => {
                if (item.description === event.target.name) {
                    let found = this.state.download.some((el) => {
                        return el.description === item.description;
                    });
                    if (!found) { this.state.download.push(item); }
                }
            })
            //console.log('added:', this.state.download)
        }

        const { dispatch } = this.props;

        dispatch(counterFiles({ files: this.state.download, count: this.state.download.length }));
    }

    /**
     * @function handleContentTypeToDisplay()
     * @description Handler listener on charge of return content to 
     * be render on realy time by states.
     */
    handleContentTypeToDisplay() {

        switch (this.state.content.type) {
            case 'embed':
                return (
                    <iframe
                        src={this.state.content.body}
                        type="text/html"
                        frameBorder="0"
                        width="100%"
                        height="660px"//"500px"
                        title={this.state.content.type} />
                )
            case 'video':
                return (
                    <video className="z-depth-1 embed-responsive embed-responsive-16by9" autoPlay loop
                        controls>
                        <source src={this.state.pdfSelected.video} type="video/mp4" />
                    </video>
                )
            case 'video-embed':
                return (
                    <div className="embed-container">
                        <iframe
                            className="z-depth-1 embed-responsive embed-responsive-16by9"
                            title={this.state.content.type}
                            src={this.state.pdfSelected.video}//"https://player.vimeo.com/video/255931354?autoplay=1" 
                            width="640"
                            height="360"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen>
                        </iframe>
                    </div>
                )
            case 'pdf':
                return (
                    <embed
                        src={this.state.pdfSelected.pdf}
                        key={this.state.pdfSelected.pdf}
                        frameBorder="0"
                        width="100%"
                        height="400px" />
                )
            case 'conference':
                return (
                    <div>
                        <Grid container spacing={3}>
                            <Grid item md={12} container justify="center">
                                <Button variant="contained"
                                    onClick={(e) => this.handleJoinAsHostFormOrAsViewerOnChange("asHost")}
                                    color="primary">Join as Host</Button>
                            </Grid>
                            <Grid item md={12} container justify="center">
                                <Button variant="contained"
                                    onClick={(e) => this.setState({
                                        content: {
                                            type: 'asViewer',
                                            title: 'Video Conference'
                                        }
                                    })}
                                    color="primary">Join as Viewer</Button>
                            </Grid>
                        </Grid>
                    </div>
                )
            case 'conference_embeded':
                return (
                    <div className="embed-container">
                        <iframe
                            className="z-depth-1 embed-responsive embed-responsive-16by9"
                            title={this.state.content.type}
                            src={this.state.pdfSelected.video}
                            width="640"
                            height="360"
                            frameBorder="0"
                            allow="camera;microphone;">
                        </iframe>
                    </div>
                )
            case 'list':
                return (
                    <List component="div" role="list" className={this.props.classes.list}>
                        {this.props.content.body.map((value) => {
                            const labelId = `checkbox-list-label-${value}`;

                            return (
                                <ListItem key={value.uuid} role="listitem" dense button divider onClick={() => this.handleToggle(value.description)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={this.state.checked.indexOf(value.description) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            id={value.description}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value.description} />
                                </ListItem>
                            );
                        })}
                    </List>
                )
            case 'asHost':
                return (
                    <div className={`${this.props.classes.formContainer}`}>
                        <Grid container spacing={3}>
                            <Grid item md={12} container justify="center">
                                <TextField
                                    id="filled-full-width"
                                    type="password"
                                    label="Password"
                                    style={{ margin: 8, width: '25rem' }}
                                    placeholder="Placeholder"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    onChange={this.handleOnChange}
                                />
                            </Grid>
                            <Grid item md={12} container justify="center">
                                <FormControl className={clsx(this.props.classes.withoutLabel, this.props.classes.textField)}>
                                    <Button variant="contained"
                                        color="primary"
                                        onClick={(e) => this.handleJoinAsHostFormOrAsViewerOnChange("asViewer")}>
                                        Submit
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item md={12} container justify="center">
                                <p className={`${this.props.classes.warningMsg}`}>{this.state.errorMessage}</p>
                            </Grid>
                        </Grid>
                    </div>
                )
            case 'asViewer':
                return (
                    <h1>Video iFrame content</h1>
                )
            default:
                return this.state.content.body
        }
    }

    /**
     * @function handleGalleryThumnail()
     * @description Handler listener on charge of render thumbnail
     * gallery real time by props-state from parent component.
     */
    handleGalleryThumnail() {

        if (!Array.isArray(this.state.content.body)) return;

        return (
            <div className={this.props.classes.root + ' btmThumbBar'}>
                <GridList className={this.props.classes.gridList} cols={5}>
                    {this.state.content.body.map((tile, i) => (
                        <GridListTile onClick={(event) => { this.handleDisplayPDFSelected(event) }} className="thumbTile" key={`${tile.img + i}`}>
                            {/* <button className="-addOrRemove" onClick={() => { this.setState({ addItem: this.state.addItem ? false : true }) }} id={`addOrRemove${i}`} data-set="addOrRemove">+</button> */}
                            <button
                                key={tile.img}
                                className="-addOrRemove"
                                name={tile.description} id={i}
                                onClick={(e) => { this.handleSetItemsToDownload(e) }}
                                data-set="selected">+</button>
                            <div className="thumb card">
                                <img className="card-img" src={tile.img} alt={tile.description} id={tile.id} data-set="selected" />
                            </div>
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        )
    }

    /**
    * @function handleJoinAsHostFormOrAsViewerOnChange()
    * @argument {string} - set section state 
    * @description Handling set section state to be display for life video
    * viewer.
    */
    handleJoinAsHostFormOrAsViewerOnChange(section) {
        this.setState({
            content: {
                type: section === 'asHost' ? 'asHost' : this.state.password
                    ? 'asViewer' : 'asHost',
                title: 'Video Conference'
            },
            errorMessage: this.state.password === null ? <></> : !this.state.password
                ? 'Invalid Password' : <></>
        })
    }

    render() {
        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth={
                        this.state.content.type === 'conference' ||
                            this.state.content.type === 'asHost' ? 'sm' : 'md'
                    }
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                    scroll='body'
                    container={() => document.querySelector('.playcanvasCon')}
                >
                    <DialogActions className={this.props.classes.titleBar}>
                        <DialogTitle className={this.props.classes.title} id="responsive-dialog-title">{this.state.content.title}</DialogTitle>
                        <Fab color="primary" onClick={this.handleClose} aria-label="close">
                            <CloseRounded />
                        </Fab>
                    </DialogActions>

                    <DialogContent>
                        {this.handleContentTypeToDisplay()}
                        {this.state.content.type === 'list'
                            ?
                            <div align="center">
                                <div className="mt-3">
                                    <strong>Would you like links to these items sent to your email instead?</strong>
                                </div>

                                <div className={`${this.props.classes.formContainer}`}>
                                    <TextField
                                        id="filled-full-width"
                                        label="Email"
                                        style={{ margin: 8, width: '25rem' }}
                                        placeholder="Placeholder"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                    />
                                    <FormControl className={clsx(this.props.classes.margin, this.props.classes.withoutLabel, this.props.classes.textField)}>
                                        <Button variant="contained" color="primary">
                                            Send
                                        </Button>
                                    </FormControl>
                                </div>

                            </div>
                            : <></>
                        }
                    </DialogContent>
                    {
                        this.state.content.type === 'pdf'
                            ? this.handleGalleryThumnail()
                            :
                            this.state.content.type === 'download'
                                ? <>
                                    <div className="-devider"></div>
                                    <DialogActions>
                                        <Button className={this.props.classes.cancelBtn} variant="contained" onClick={this.handleClose}>
                                            Cancel
                                        </Button>
                                        <div>
                                            No thanks, just
                                        </div>
                                        <Button onClick={this.handleDownloadFiles} color="primary" variant="contained" align="end">
                                            Download Selected
                                        </Button>
                                    </DialogActions>
                                </>
                                :
                                <></>
                    }
                </Dialog>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        count: state.auth.count
    };
}

export default withStyles(useStyles)(connect(mapStateToProps)(DialogContainer));