import React from 'react';

import Pages from './routes/index.jsx';

class App extends React.Component {
  
  render() {
    return (
      <>
        <Pages />
      </>
    );
  }
}

export default App;
