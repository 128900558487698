import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Home from '../modules/home/index.jsx';

class HomeComponentView extends React.Component  {
  render() {
    return (
      <div>
        <Home />    
      </div>
    )
  }

}

export default HomeComponentView;
