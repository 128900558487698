import React from 'react';
import LoginComponent from './_components/loginComponent.jsx'
class Login extends React.Component {
  render() {
    return (
      <>
      <LoginComponent />
      </>
    )
  }
}

export default Login;
